body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* MODAL CALENDAR STYLES */
@media screen and (max-width: 599px) {
  .MuiPickersModal-dialogRootWider {
    min-width: 240px !important;
    margin: 10px !important;
  }
  .MuiPickersBasePicker-container .MuiTypography-subtitle1 {
    font-size: 14px !important;
  }
  .MuiPickersBasePicker-container .MuiTypography-h3 {
    font-size: 40px !important;
  }
  .MuiPickersBasePicker-container .MuiTypography-h4 {
    font-size: 28px !important;
  }
  .MuiPickersBasePicker-container .MuiPickersCalendarHeader-dayLabel {
    width: 32px !important;
  }
  .MuiPickersBasePicker-container .MuiPickersBasePicker-pickerView {
    min-width: 280px !important;
    min-height: 290px !important;
  }
  .MuiPickersBasePicker-container .MuiPickersDay-day {
    width: 32px !important;
    height: 32px !important;
  }
  .MuiPickersBasePicker-container .MuiTabs-root,
  .MuiPickersBasePicker-container .MuiTab-fullWidth {
    min-height: 30px;
  }
}

/* HIDE ARROW NAVIGATIONS IN NUMBER SELECT */
/* I didn't find a way how to make this work in JSS therefore it's here */

.hideNumberArrows input::-webkit-outer-spin-button,
.hideNumberArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hideNumberArrows input[type='number'] {
  -moz-appearance: textfield;
}

/* CALENDAR */
.calendar-day-class {
  width: 38px !important;
  line-height: 38px !important;
}

.react-datepicker__day-name {
  width: 38px !important;
  line-height: 38px !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}
